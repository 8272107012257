.shadow-transition {
  box-shadow:  0 1.5625rem 2.8125rem rgba($gray-900, .075);
  transition: box-shadow 0.4s ease;
}




  .square {
    position: relative;
    display: inline-block;
    &::before {
      position: absolute;
      content: '';
      height: 20rem;
      width: 20rem;
      border-radius: 1.8rem;
      z-index: -1 !important;
      background-image: linear-gradient(200deg, rgba(255, 248, 244, 1),rgba(255, 232, 219, 1));
    }
  }
  
  .shadow-square-bottom {
    &::before {
      top: 5rem;
      left: 5rem;
    }
  }
  .shadow-square-top {
    &::before {
      top: .6rem;
      left: 1rem;
    }
  }
  


  .circle-danger {
    &:before {
      box-shadow: 0 0 12.5rem 3.75rem $danger;
    }
  }
  
  .circle-warning {
    &:before {
      box-shadow: 7.5rem  0  48.75rem 4.0625rem $warning;
    }
  }
  
  .circle-cyan {
    &:before {
      box-shadow: 9.375rem 15.625rem 50rem 3.125rem $cyan;
    }
  }
  .circle-primary {
    &:before {
      box-shadow: 0 0 18.75rem 6.25rem $primary;
    }
  }
  .circle-pink {
    &:before {
      box-shadow: 0 15.625rem 50rem 4.6875rem $pink;
    }
  }
  
  
  .circle {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 12.5rem;
      width: 12.5rem;
      border-radius: 50%;
      // z-index: -1 !important;
      top: 0;
      left: 0;
      transform: translate3d(-50%, -50%, 0);
      background-image: linear-gradient(200deg, rgba(86 , 151, 250 ,0), #4e92f94a);
    }
  }